import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "text"];

  connect() {
    this.selectTarget.addEventListener("click", this.selectAll.bind(this));
    this.element.addEventListener("change", this.updateForm.bind(this));
    this.showHideButton();
  }

  selectAll() {
    const checkboxes = this.element.querySelectorAll("input[type=checkbox]:not(:disabled)");
    const isChecked = this.selectTarget.checked;

    checkboxes.forEach(checkbox => {
      checkbox.checked = isChecked;
    });

    this.textTarget.innerHTML = isChecked ? "Tout désélectionner" : "Tout sélectionner";
    this.updateForm();
  }

  updateForm() {
    this.showHideButton();

    const total = [...this.element.querySelectorAll("input[type=checkbox]:checked:not(:disabled):not(#select_all)")]
      .reduce((total, checkbox) => total + (parseInt(checkbox.dataset.amount) || 0), 0);

    this.element.querySelectorAll("#payButton").forEach(payButton => {
      payButton.value = `Payer ${total} fcfa`;
    });
  }

  showHideButton() {
    const payButtons = this.element.querySelectorAll("#payButton");
    const isAnyChecked = [...this.element.querySelectorAll("input[type=checkbox]:checked:not(:disabled):not(#select_all)")].length > 0;

    payButtons.forEach(payButton => {
      payButton.style.display = isAnyChecked ? "flex" : "none";
    });
  }
}
