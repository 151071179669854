import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "jury", "center", "sector" ]
  onChange(e) {
    e.preventDefault();

    this.juryTarget.classList.add('is-hidden');
    this.centerTarget.classList.add('is-hidden');
    this.sectorTarget.classList.add('is-hidden');

    const value = e.target.value;

    if (value == 'jury') {
      this.juryTarget.classList.remove('is-hidden');
    }

    if (value == 'center') {
      this.centerTarget.classList.remove('is-hidden');
    }

    if (value == 'sector') {
      this.sectorTarget.classList.remove('is-hidden');
    }
  }
}
