import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "destroy" ];

  connect(){
    this.element.style.display = "flex";
    let blink = this.element.querySelector('#blink'); 
  
    setInterval(function () { 
        blink.style.opacity =  
        (blink.style.opacity == 0 ? 1 : 0); 
    }, 1000);
  }

  destroy(e) {
    e.preventDefault();
    this.element.style.display = "none";
  }
}
