import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.parent = this.data.get('parent');
    this.select = this.data.get('select');
    this.selected = this.data.get('selected');
    this.parentElt = document.querySelector(this.parent);
    this.selectElt = document.querySelector(this.select);
    this.url = this.data.get('url');
    this.parentElt.addEventListener("change", this.loadChild.bind(this));
    
  }

  loadChild() {
    const parentId = this.parentElt.value;
    Rails.ajax({
      url: `${this.url}?parent_id=${parentId}`,
      type: "get",
      success: ( (data) => this.onLoad(data) )
    });
  }

  onLoad(data) {
    const pasedData = data.map(e => { return {'value': e.value, 'label': e.label, 'selected': (e.value == this.selected ? true : false)} });
    this.selectElt.choices.clearStore();
    this.selectElt.choices.setChoices(pasedData);
  }
}
