import { Controller } from "stimulus"
import { initDatepicker, initTimepicker } from "controllers/utilities/datepicker.js";

export default class extends Controller {
  static targets = [ 'input' ];

  connect() {
    this.type = this.data.get('type');

    if (this.type === 'time')  {
      this.picker = initTimepicker(this.inputTarget);
    }
    else {
      this.picker = initDatepicker(this.inputTarget);
      this.picker.config.onOpen.push(this.setDefaultDate.bind(this));
    }
  }

  clear(e) {
    e.preventDefault();
    this.picker.clear();
  }

  setDefaultDate() {
    if (!this.picker.selectedDates.length) {
      this.picker.setDate('2000/01/01');
      console.log(this.picker)
    }
  }
}
