import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['paymentConfirmed'];

  connect() {
    this.candidate_type = document.querySelector('#candidate_candidate_type');
    this.school_id = document.querySelector('#candidate_school_id');
    this.paymentConfirmed = this.paymentConfirmedTarget.value
    console.log(this.paymentConfirmed)
    if(this.paymentConfirmed == "true"){
      this.candidate_type.closest(".field").classList.add("is-hidden");
      this.school_id.closest(".field").classList.add("is-hidden");
    }else{
      this.candidate_type.closest(".field").classList.remove("is-hidden");
      this.school_id.closest(".field").classList.remove("is-hidden");
      this.candidate_type.addEventListener("change", this.candidateTypeChange.bind(this)); 
    }
    this.candidateTypeChange()
  }

  candidateTypeChange() {
    const candidate_type = this.candidate_type.value;
    if(candidate_type == 'free_candidate'){
      this.school_id.closest(".field").classList.add("is-hidden");
    }else{
      if(candidate_type == 'official_candidate'){
      this.school_id.closest(".field").classList.remove("is-hidden");
      }
    }
  }

}
