import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex';

let MOBILE_MAX_CAPACITIES = [
  {
    service: "airtel",
    regex: /^(9[6-9]|8[6-9]|77)[0-9]{6}$/,
    capacity: 1000000.0
  },
  {
    service: "moov",
    regex: /^(9[4-5]|8[4-5]|7[4-5])[0-9]{6}$/,
    capacity: 1000000.0
  },
  {
    service: "zamani",
    regex: /^(9[0-2]|8[0-2]|70)[0-9]{6}$/,
    capacity: 1000000.0
  },
]

let STA_MAX_CAPACITIES = [
  {
    service: "alizza",
    capacity: 5000000.0
  },
]

export default class extends Controller {

  static values = {
    paymentListId: Number,
    amount: Number
  }

  static targets = [ "phone", "modal", "message", "pay" ]

  initialize() {
    StimulusReflex.register(this);
  }

  connect() {
    this.setMaxValues(!this.isDevelomentEnvironment() ? 500 : 1000000)
    this.body = document.querySelector('body');
    this.body.addEventListener("paymentsList:payment:updated", this.reloadPaymentsListPage.bind(this));
  }

  reloadPaymentsListPage(e) {
    const paymentListId = e.detail['paymentsListId'];
    if (this.paymentListIdValue == paymentListId) {
      this.stimulate('PaymentsListReflex#reload');
    }
  }

  checkCapacity(e) {
    const mobileChecked = this.element.querySelector("#mobile").checked;
    const alizzaChecked = this.element.querySelector("#alizza").checked;
    const phoneNotEmpty = this.phoneTarget.value !== "";
  
    if (phoneNotEmpty) {
      if(mobileChecked){
        const operator = MOBILE_MAX_CAPACITIES.find((op) => op.regex.test(this.phoneTarget.value));
    
        if (operator && this.amountValue > operator.capacity) {
          this.showMessage(`Le montant ne doit pas excéder ${operator.capacity} pour ${operator.service}.`);
        } else if (operator) {
          this.payTarget.click();
        } else {
          this.showMessage("Ce numéro de téléphone n'est pas reconnu.");
        }
      } else{
        const operator = STA_MAX_CAPACITIES.find((op) => op.service == "alizza");
    
        if (operator && this.amountValue > operator.capacity) {
          this.showMessage(`Le montant ne doit pas excéder ${operator.capacity} pour Al Izza.`);
        } else
        if (operator) {
          this.payTarget.click();
        } else {
          this.showMessage("Ce service n'est pas pris en charge.");
        }
      }
    } else{
      this.showMessage("Vous devez renseigner un numéro de téléphone");
    }
  }
  
  showMessage(message) {
    this.messageTarget.innerHTML = message;
    this.modalTarget.style.display = "flex";
  }
  
  close(e) {
    e.preventDefault();
    this.modalTarget.style.display = "none";
  }

  setMaxValues(value){
    STA_MAX_CAPACITIES.concat(MOBILE_MAX_CAPACITIES).forEach(service => {
      service.capacity = value
    })
  }

  isDevelomentEnvironment() {
    return document.head.querySelector("meta[name=rails_env]").content == "development"
  }
  
}
