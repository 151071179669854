import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {

  connect() {
  }

  update_filters(e) {
    e.preventDefault();
    var data = {}
    var base_model = this.element.querySelector("select[name='base_model']").value.toLowerCase();
    data.base_model = base_model;
    data.joins = this.element.querySelector('#filters-section').dataset.joins;
    if(data.joins.includes('[') && data.joins.includes(']')){
      data.joins = JSON.parse(data.joins);
    }
    if(this.element.querySelector("select[name='possible_joins[base_model]']")){
      var join = this.element.querySelector("select[name='possible_joins[base_model]']").value.toLowerCase();
      if(typeof data.joins == 'string'){
        data.joins = [join];
      }else{
        data.joins[data.joins.length] = join;
      }
    }
    $.ajax({
      url: 'fetch_filters',
      method: 'POST',
      data: data,
      dataType: 'script'
    });
  }
  
  add_join(){
    var model = this.element.querySelector("select[name='joins[base_model]']").value.toLowerCase();
    $.ajax({
      url: 'fetch_joins',
      method: 'POST',
      data: { model: model, base_model: base_model },
      dataType: 'script'
    });
  }

  
}
