import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  connect() {
    this.candidate_type = document.querySelector('#candidate_candidate_type');
    this.school_id = document.querySelector('#candidate_school_id');
    this.candidate_type.addEventListener("change", this.candidateTypeChange.bind(this)); 
  }

  candidateTypeChange() {
    const candidate_type = this.candidate_type.value;
    if(candidate_type == 'free_candidate'){
      this.school_id.closest(".field").classList.add("is-hidden");
    }else{
      if(candidate_type == 'official_candidate'){
      this.school_id.closest(".field").classList.remove("is-hidden");
      }
    }
  }


}
