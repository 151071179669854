import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  static values = {
    candidateId: Number
  }

  initialize() {
    StimulusReflex.register(this);
  }

  connect() {
    this.body = document.querySelector('body');
    this.body.addEventListener("candidate:payment:updated", this.reloadPaymentPage.bind(this));
  }

  reloadPaymentPage(e) {
    const candidateId = e.detail['candidateId'];
    if (this.candidateIdValue == candidateId) {
      this.stimulate('PaymentReflex#reload', candidateId);
    }
  }

  
}
