import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'personal', 'exam', 'files', 'quitus', 'payment', 'currentStep', 'schoolField' ];

  connect() {

  }

  displayPreviousStep(e) {
    e.preventDefault();
    const step = e.target.dataset['step'];
    this._hideAllSteps();
    this._updateCurrentStep();
    this[`${step}Target`].classList.remove('d-none');
  }

  toggleSchoolField(e) {
    const value = e.target.value;

    if (value == 'official_candidate') {
      this.schoolFieldTarget.classList.remove('d-none');
    }
    else {
      this.schoolFieldTarget.classList.add('d-none');
    }
  }

  _hideAllSteps() {
    Array.from(document.querySelectorAll('.inscription-step')).forEach(elt => elt.classList.add('d-none'));
  }

  _updateCurrentStep() {
    const value = this[`currentStepTarget`].value;
    this[`currentStepTarget`].value = parseInt(value) - 1;
  }
}
